$enable-rounded: false;

@import '~bootstrap/scss/bootstrap';

@include media-breakpoint-up(sm) { 
    .bookmarks-table {
        table-layout: fixed;

        &__col {
            text-overflow: ellipsis;
            overflow-wrap: break-word;
    
            &--name {
                width: 30%;
            }
    
            &--url {
                width: 40%;
            }
        }
    }
}

.video-result {
    &__image {
        height: 300px;
        object-fit: cover;
    }
}